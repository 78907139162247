import { takeLatest, call, put, select } from 'redux-saga/effects'
import * as actions from '../actions'
import {
  getEnabledFeatures,
  getWorkflowStep,
  getBundleBuilderData,
  updateBundleBuilderData,
  getAuthenticationToken,
  saveBanModalInfo
} from '../services/session'
import { navigateParentWindow, parseQueryString } from '../utils/navigation'
import { workflowStepIds } from '../constants/session'
import { selectThemeType } from '../selectors/session'
import { getEnv } from '../utils/env'

export function* getSessionDataSaga({ payload }) {
  const { opportunityId } = payload
  const enabledFeatures = yield call(getEnabledFeatures, payload)

  yield put(
    actions.setSessionData({
      ...payload,
      enabledFeatures
    })
  )
  // TODO: Needs discussion since opp id won't be returned in the future
  yield put(actions.joinSalesforceSessionUrls(opportunityId))
}

export function* getCurrentWorkflowStep() {
  const { fxbuyflowSessionId } = yield select(state => state.session)

  yield put(actions.togglePageLoading())
  const { workflowStepId } = yield call(getWorkflowStep, {
    fxbuyflowSessionId
  })
  yield put(actions.setSessionData({ workflowStepId }))
  yield put(actions.togglePageLoading(false))
}

export function* initializeWorkflowStep({ payload }) {
  const { workflowStepId, defaultRoute, continueOrderRoute } = yield call(
    getWorkflowStep,
    payload
  )

  yield put(
    actions.setSessionData({
      initialWorkflowStepId: workflowStepId,
      workflowStepId
    })
  )
  yield put(
    actions.setNavigation({
      defaultRoute,
      continueOrderRoute
    })
  )

  if (workflowStepId === workflowStepIds.ORDER_SUBMITTED) {
    yield put(actions.setOrderIsSubmitted(true))
  }
}

export function* getBundleBuilderDataSaga({ payload }) {
  const { fxbuyflowSessionId } = yield select(({ session }) => session)
  const { showSpinner } = payload

  yield put(actions.togglePageLoading(showSpinner))
  const bundleBuilder = yield call(getBundleBuilderData, fxbuyflowSessionId)

  // Do before setting data to avoid spinner/modal overlap
  yield put(actions.togglePageLoading(false))
  yield put(
    actions.setSessionData({
      bundleBuilder: { ...bundleBuilder, showModal: true }
    })
  )
}

export function* updateBundleBuilderDataSaga({ payload }) {
  const { reasons, isLimitBB } = payload
  const {
    sessionId,
    bundleBuilder,
    opportunityId,
    fxbuyflowSessionId
  } = yield select(({ session }) => session)

  try {
    if (!isLimitBB) {
      yield put(actions.hideBundleBuilderModal())
    }
    yield put(actions.togglePageLoading())
    yield call(updateBundleBuilderData, {
      sessionId,
      opportunityId,
      reasons,
      fxbuyflowSessionId
    })

    navigateParentWindow(bundleBuilder.redirectUrl)
  } catch (error) {
    if (isLimitBB && error.code === 'SRTICKET_NOTFOUND') {
      yield put(actions.togglePageLoading(false))
      yield put(
        actions.setSessionData({
          bundleBuilder: { ...bundleBuilder, showSrTicketError: true }
        })
      )
    } else {
      navigateParentWindow(bundleBuilder.redirectUrl)
    }
  }
}

export function* getAuthenticationTokenSaga({ payload }) {
  const { guid } = payload

  try {
    const orderSessionData = yield call(getAuthenticationToken, guid)

    yield put(
      actions.setSessionData({
        ...orderSessionData,
        isAuthorized: true
      })
    )
  } catch {
    yield put(
      actions.setSessionData({
        isAuthorized: false
      })
    )
  }
}

export function* toggleUserThemePreferenceSaga() {
  const themeType = yield select(selectThemeType)
  localStorage.setItem('userThemePreference', themeType)
}

export function* saveBanModalInfoSaga({ payload }) {
  const { HOST_ENV } = getEnv()
  const { location } = yield select(({ router }) => router)
  const { fxbuyflowSessionId: buyflowId } = yield select(
    ({ session }) => session
  )
  const params = parseQueryString(location.search)
  const { banNumber } = payload

  const postPayload = {
    banNumber,
    fxbuyflowSessionId:
      HOST_ENV === 'local' ? location.query.fxbuyflowSessionId : buyflowId
  }

  try {
    yield put(actions.togglePageLoading())
    const banSaved = yield call(saveBanModalInfo, postPayload)
    if (banSaved) {
      yield put(
        actions.setSessionData({
          isBanToggleEnabled: false
        })
      )
      yield put(actions.getSessionData({ ...params, accountNumber: banNumber }))
      yield put(actions.getOrder(params))
    }
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

function* rootSaga() {
  yield takeLatest(actions.GET_SESSION_DATA, getSessionDataSaga)
  yield takeLatest(actions.INITIALIZE_WORKFLOW_STEP, initializeWorkflowStep)
  yield takeLatest(actions.GET_CURRENT_WORKFLOW_STEP, getCurrentWorkflowStep)
  yield takeLatest(actions.GET_BUNDLE_BUILDER_DATA, getBundleBuilderDataSaga)
  yield takeLatest(
    actions.UPDATE_BUNDLE_BUILDER_DATA,
    updateBundleBuilderDataSaga
  )
  yield takeLatest(actions.GET_AUTHENTICATION_TOKEN, getAuthenticationTokenSaga)
  yield takeLatest(actions.TOGGLE_THEME, toggleUserThemePreferenceSaga)
  yield takeLatest(actions.SAVE_BAN_MODAL_INFO, saveBanModalInfoSaga)
}

export default rootSaga
