import { getEnv } from './env'
import { stringifyQueryString, navigateParentWindow } from './navigation'

export function redirectOnError(url, queryStrings = {}) {
  const { HOST_ENV } = getEnv()
  const qs = stringifyQueryString(queryStrings)

  if (HOST_ENV !== 'local') {
    navigateParentWindow(`${url}?${qs}`)
  }
}

export function handleRedirectError(error, opportunityId) {
  const { redirectURL, ...data } = error.data || {}

  if (redirectURL) {
    redirectOnError(redirectURL, {
      ...data,
      id: opportunityId
    })
  }
}

// Returns error message/redirect URL for API error if found
export function getApiErrorMessage({ response = {} } = {}) {
  const { messages = [] } = response.data || {}

  return messages.reduce((error, msg, index) => {
    // only return the first message
    if (index === 0) {
      return msg
    }

    // add redirectUrl if it exists
    if (msg.redirectUrl) {
      return {
        ...error,
        redirectUrl: msg.redirectUrl
      }
    }

    return error
  }, null)
}

export function createRedirectError(rawError) {
  const errorMsg = getApiErrorMessage(rawError) || {}
  const error = new Error()

  error.data = {
    redirectURL: errorMsg.redirectUrl,
    error: errorMsg.description,
    errorCode: errorMsg.code || rawError.response.status,
    scontrolCaching: 1
  }

  return error
}
