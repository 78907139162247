import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { selectSections } from '../selectors/navigation'
import withRouteSuspense from '../hocs/withRouteSuspense'
import { salesforcePath, unauthorizedPath } from '../constants/navigation'
import * as actions from '../actions'
import { stringifyQueryString, parseQueryString } from '../utils/navigation'
import { sessionPropTypes } from '../propTypes'
import ErrorLogger from '../components/core/ErrorLogger'
import { getEnv } from '../utils/env'

const settingRoutes = React.lazy(() => import('./settings/Routes'))
const orderRoutes = React.lazy(() => import('./orders/Routes'))
const authorizationRoutes = React.lazy(() => import('./authorization/Routes'))
const singNowRoutes = React.lazy(() => import('./signNow/Routes'))
const bundleBuilderRoutes = React.lazy(() =>
  import('./bundleBuilderRedirect/Routes')
)
const SettingsRoutes = withRouteSuspense(settingRoutes)
const OrderRoutes = withRouteSuspense(orderRoutes)
const AuthorizationRoutes = withRouteSuspense(authorizationRoutes)
const SignNowRoutes = withRouteSuspense(singNowRoutes)
const BundleBuilderRedirectRoutes = withRouteSuspense(bundleBuilderRoutes)

export const Routes = ({ sections, session, onAuthorization, onError }) => {
  const { HOST_ENV } = getEnv()
  const enableErrorLogging = HOST_ENV !== 'local'
  const { isAuthorized } = session

  function getDefaultOrderPath() {
    const {
      opportunityId,
      accountNumber,
      agentId,
      fxbuyflowSessionId
    } = session
    const sessionQueryString = stringifyQueryString({
      opportunityId,
      accountNumber,
      agentId,
      fxbuyflowSessionId
    })

    return `${salesforcePath}${sections.orders.path}?${sessionQueryString}`
  }

  function getRedirectPath(search) {
    const { redirectUrl } = parseQueryString(search)

    if (!isAuthorized) {
      return unauthorizedPath
    }

    if (redirectUrl) {
      return redirectUrl
    }

    return getDefaultOrderPath()
  }

  function renderApp() {
    return (
      <>
        <Switch>
          <Route
            path="/sign-now"
            component={SignNowRoutes}
          />
          <Route
            path={salesforcePath}
            render={routeProps => {
              const { match, location } = routeProps
              const { search } = location

              if (!isAuthorized) {
                return <Redirect to={getRedirectPath(location.search)} />
              }

              return (
                <Switch>
                  <Route
                    path={`${match.path}${sections.settings.path}`}
                    component={SettingsRoutes}
                  />
                  <Route
                    path={[
                      `${match.path}${sections.orders.path}/:pageId`,
                      `${match.path}${sections.orders.path}`
                    ]}
                    component={OrderRoutes}
                  />
                  <Redirect
                    to={`${salesforcePath}${sections.orders.path}${search}`}
                  />
                </Switch>
              )
            }}
          />
          <Route
            exact
            path={['', '/:guid']}
            render={routerProps => (
              <AuthorizationRoutes
                isAuthorized={isAuthorized}
                redirectPath={getRedirectPath(routerProps.location.search)}
                onAuthorization={onAuthorization}
                {...routerProps}
              />
            )}
          />
          {/* BB redirect route  */}
          <Route
            path={['', '/:guid/:keystring']}
            exact
            render={routerProps => (
              <BundleBuilderRedirectRoutes
                isAuthorized={isAuthorized}
                redirectPath={getRedirectPath(routerProps.location.search)}
                onAuthorization={onAuthorization}
                {...routerProps}
              />
            )}
          />
        </Switch>
      </>
    )
  }

  if (enableErrorLogging) {
    return <ErrorLogger onError={onError}>{renderApp()}</ErrorLogger>
  }

  return renderApp()
}

Routes.propTypes = {
  sections: PropTypes.objectOf(PropTypes.object),
  session: sessionPropTypes,
  onAuthorization: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

export const mapStateToProps = state => ({
  session: state.session,
  sections: selectSections(state)
})

const mapDispatchToProps = {
  onAuthorization: actions.getAuthenticationToken,
  onError: actions.logError
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
