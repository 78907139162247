import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as actions from '../actions'
import { formatCustomer, getSaveCustomerPayload } from '../utils/customer'
import {
  getCustomer,
  getCustomerDebt,
  updateCustomerInfo,
  getBannerMessages,
  processBannerInteraction
} from '../services/customer'
import { validateSecurityEdgeEmailSaga } from './validations/internet'
import { handleRedirectError } from '../utils/error'
import { selectCustomerDetails } from '../selectors/customer'
import { confirmCustBilling } from '../constants/form'
import { errors } from '../constants/content'
import { customerEventSources } from '../constants/customer'

export function* getCustomerSaga({ payload = {} }) {
  try {
    const customer = yield call(getCustomer, payload)
    yield put(actions.setCustomerDetails(formatCustomer(customer)))
  } catch (error) {
    handleRedirectError(error, payload.opportunityId)
    yield put(actions.setCustomerDetails(null))
  }
}

export function* saveCustomerSaga({ payload = {} }) {
  const { customer } = payload

  try {
    const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
      ({ session }) => session
    )
    const request = getSaveCustomerPayload(customer)

    yield put(actions.togglePageLoading())
    yield call(updateCustomerInfo, request, {
      opportunityId,
      sessionId,
      fxbuyflowSessionId
    })
    yield put(actions.setCustomerDetails(customer))
  } catch {
    yield put(actions.updateCustomerDetails(customer))
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* getCustomerDebtSaga({ payload = {} }) {
  const { sessionId } = payload

  const debt = yield call(getCustomerDebt, sessionId)
  yield put(actions.setCustomerDebt(debt))
}

export function* getBannerMessagesSaga() {
  const { customer: { banner } = {} } = yield select(state => state)
  if (!banner) {
    const bannerMessages = yield call(getBannerMessages)
    yield put(actions.setBannerMessages(bannerMessages))
  }
}

export function* processBannerInteractionSaga(bannerPayload) {
  const { agentId, loggedInAgentId, opportunityId } = yield select(state =>
    state.session)
  const { bannerId, bannerContent } = bannerPayload.payload
  const payload = {
    ownerAgentId: agentId,
    loggedInAgentId,
    opportunityId,
    bannerId,
    bannerContent,
    'clickTimestampUtc': new Date().toISOString()
  }
  yield call(processBannerInteraction,payload)
}

export function* setCustomerSaga({ payload }) {
  const { details } = payload
  const internetConfig = yield select(
    ({ configuration }) => configuration.internet || {}
  )

  if (
    Object.keys(internetConfig).length &&
    details?.contact?.hasOptedOutOfEmail === true
  ) {
    yield* validateSecurityEdgeEmailSaga()
  }
}

export function* confirmCustomerBillingAddressSaga({ payload }) {
  const customer = yield select(selectCustomerDetails)
  const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const request = {
    ...getSaveCustomerPayload(customer),
    ...payload,
    eventSource: customerEventSources.confirmBilling
  }

  try {
    yield put(actions.togglePageLoading())
    yield call(updateCustomerInfo, request, {
      opportunityId,
      sessionId,
      fxbuyflowSessionId
    })
    yield put(
      actions.setCustomerDetails(
        formatCustomer({
          ...customer,
          account: {
            ...customer.account,
            ...payload,
            isBillingAddressConfirmed: true
          }
        })
      )
    )
  } catch ({ message }) {
    yield put(actions.setGlobalError(confirmCustBilling, errors.addressConfirm))
    yield put(
      actions.updateCustomerDetails(
        formatCustomer({
          ...customer,
          account: {
            ...customer.account,
            isBillingAddressConfirmed: true
          }
        })
      )
    )
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

function* rootSaga() {
  yield takeLatest(actions.GET_CUSTOMER_DETAILS, getCustomerSaga)
  yield takeLatest(actions.SAVE_CUSTOMER, saveCustomerSaga)
  yield takeLatest(actions.GET_CUSTOMER_DEBT, getCustomerDebtSaga)
  yield takeLatest(actions.GET_BANNER_MESSAGES, getBannerMessagesSaga)
  yield takeLatest(actions.PROCESS_BANNER_MESSAGES,
    processBannerInteractionSaga)
  yield takeLatest(actions.SET_CUSTOMER, setCustomerSaga)
  yield takeLatest(
    actions.CONFIRM_CUSTOMER_BILLING_ADDRESS,
    confirmCustomerBillingAddressSaga
  )
}

export default rootSaga
