import { calendarTypes } from '../constants/scheduling'
import { formatDate } from '../utils/dates'
import { appTypes, workflowStepIds } from '../constants/session'
import { isSameWorkflowStepRange } from '../utils/session'
import { selectCustomerDetails } from './customer'
import { selectActivePageId } from './router'
import { orderRouteIds } from '../constants/navigation'
import { configTypes } from '../constants/configuration'
import {
  cbmEligibilityTypes,
  cbmMessages,
  cbmStatusTypes
} from '../constants/order'

const DATE_FORMAT = 'YYYY-MM-DD'
const { ORDER_REJECTED } = workflowStepIds

export function selectOrderSubmit({ session, scheduling }) {
  const { opportunityId, sessionId, fxbuyflowSessionId } = session
  const { notes, calendar } = scheduling
  const {
    install,
    prewire,
    shouldEscalate,
    escalation,
    escalationReason
  } = calendar
  const hasCddCalendar =
    install?.calendarType.toLowerCase() === calendarTypes.cddd

  return {
    opportunityId,
    sessionId,
    fxbuyflowSessionId,
    schedulingNotes: notes,
    [hasCddCalendar
      ? 'selectedCdddTime'
      : 'selectedDateOfInstallTime']: install && {
      date: formatDate(install.selected.date, DATE_FORMAT),
      timeSlot: {
        id: install.selected.timeSlot.id,
        time: install.selected.timeSlot.time
      }
    },
    selectedPreWireTime: prewire && {
      date: formatDate(prewire.selected.date, DATE_FORMAT),
      timeSlot: {
        id: prewire.selected.timeSlot.id,
        time: prewire.selected.timeSlot.time
      }
    },
    ...(shouldEscalate && {
      selectedEscalationTime: {
        date: formatDate(escalation.selected.date, DATE_FORMAT),
        timeSlot: {
          id: escalation.selected.timeSlot.id,
          time: escalation.selected.timeSlot.time
        }
      },
      escalationReason
    })
  }
}

export function selectIsOrderRejected(state) {
  return isSameWorkflowStepRange(state.session?.workflowStepId, ORDER_REJECTED)
}

export function selectCbmEligibility(state) {
  const {
    cbmStatus,
    cbmStandaloneOrderFlowEnabled,
    isCBMTaxExemptEligible,
    isMovesCbmActive
  } = state.order
  const isAdvancedMoveOrder = selectIsAdvancedMoveOrder(state)
  const { account } = selectCustomerDetails(state)

  const { appType } = state.session

  if ((appType === appTypes.NC) && !isAdvancedMoveOrder &&
  (!cbmStatus || cbmStatus === cbmStatusTypes.notEligible)) {
    return cbmEligibilityTypes.notEligible
  }
  const pageId = selectActivePageId(state)

  switch (pageId) {
    case orderRouteIds.offers: {
      if (account.taxExempt) {
        if (isCBMTaxExemptEligible) {
          return cbmEligibilityTypes.eligible
        }
        return cbmEligibilityTypes.taxExemptEligible
      }

      if (!cbmStatus || cbmStatus === cbmStatusTypes.notEligible ) {
        return cbmEligibilityTypes.notEligible
      }
      

      if (cbmStatus === cbmStatusTypes.hasCbm) {
        return cbmEligibilityTypes.hasCbm
      }

      return isAdvancedMoveOrder
        ? cbmEligibilityTypes.notEligible
        : cbmEligibilityTypes.eligible
    }

    case orderRouteIds.configure: {
      if (cbmStandaloneOrderFlowEnabled) {
        return cbmEligibilityTypes.eligible
      }

      if (account.taxExempt) {
        if (isCBMTaxExemptEligible) {
          return cbmEligibilityTypes.eligible
        }
        return cbmEligibilityTypes.taxExemptEligible
      }

      if (cbmStatus === cbmStatusTypes.hasCbm) {
        return cbmEligibilityTypes.hasCbm
      }
      
      if (isMovesCbmActive) {
        return cbmEligibilityTypes.notEligible
      }

      const { lineOfBusinesses = [] } = state.configuration?.offer || {}
      const hasInternet = lineOfBusinesses.includes(configTypes.internet)
      
      return hasInternet
        ? cbmEligibilityTypes.eligible
        : cbmEligibilityTypes.noInternetEligible
    }

    case orderRouteIds.documents:
    case orderRouteIds.dashboard:
    case orderRouteIds.submit:
    case orderRouteIds.success: {
      const { isCbmAdded } = state.cart?.details?.additionalServices || {}

      if (account.taxExempt) {
        if (isCBMTaxExemptEligible) {
          if (isCbmAdded) {
            return cbmEligibilityTypes.addedCBM
          }
          return cbmEligibilityTypes.notEligible
        }
        return cbmEligibilityTypes.taxExemptEligible
      }

      if (isCbmAdded) {
        return cbmEligibilityTypes.addedCBM
      }

      return cbmEligibilityTypes.notEligible
    }

    default: {
      return cbmEligibilityTypes.eligible
    }
  }
}

export function selectIsCurrentCbmStatus(cbmTypes = []) {
  return state => cbmTypes.includes(selectCbmEligibility(state))
}

export function selectIsCbmAddedToOrder(state) {
  return selectCbmEligibility(state) === cbmEligibilityTypes.addedCBM
}

export function selectCbmMessage(state) {
  const { cbmMessages: messages, isMovesCbmActive } = state.order
  const eligibilityType = selectCbmEligibility(state)
  const pageId = selectActivePageId(state)
  const hasCbmMessages = !!messages?.length
  const isAdvancedMoveOrder = selectIsAdvancedMoveOrder(state)

  if (eligibilityType === cbmEligibilityTypes.notEligible) {
    switch (pageId) {
      case orderRouteIds.offers:
      case orderRouteIds.configure: {
        if(isMovesCbmActive){
          return cbmMessages[cbmEligibilityTypes.notEligibleActiveCbm]?.[pageId]
        }
        if (isAdvancedMoveOrder) {
          return cbmMessages[cbmEligibilityTypes.notEligibleEC]?.[pageId]
        }
        return hasCbmMessages ? { messages, messageType: 'warning' } : null
      }

      default:
        return null
    }
  }

  const cbmMessage = cbmMessages[eligibilityType]?.[pageId]

  // Need to return null cbmMessages
  if (cbmMessage !== undefined) {
    return cbmMessage
  }

  return { messages, messageType: 'success' }
}

export function selectProjectCodes(state) {
  return state.order?.projectCodes
}

export function selectIsMoveSelected(state) {
  return state.order?.orderMoveDetails?.isMoveSelected
}

export function selectHasMoveDetailsAvailable(state) {
  const { orderMoveDetails = {} } = state.order || {}

  return Object.keys(orderMoveDetails).length !== 0
}

export function selectCBMStandaloneOrderFlowEnabled(state) {
  return state.order?.cbmStandaloneOrderFlowEnabled
}

export function selectIsAdvancedMoveOrder(state) {
  const { order } = state

  return (
    order?.orderMoveDetails?.isAdvancedMoveOrder &&
    order?.orderMoveDetails?.isMoveOrder
  )
}
